:-webkit-any(:fullscreen, :-webkit-full-screen, :fullscreen) {
  background-color: #fff0;
}

:-moz-any(:fullscreen, :fullscreen, :fullscreen) {
  background-color: #fff0;
}

:is(:fullscreen, :fullscreen, :fullscreen) {
  background-color: #fff0;
}

h2 {
  font-size: 1.4em;
}

@media print {
  header {
    display: block;
  }

  #boxScale, #boxbind, #boxScale, #moveBox, #zoomBox, #boxinfo {
    display: none;
  }

  #lin, #boxgrid {
    width: 27cm;
    height: 19cm;
  }

  #areaValue {
    top: 10px;
    left: 0;
  }

  *, :before, :after, :first-letter, p:first-line, div:first-line, blockquote:first-line, li:first-line {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }
}

body {
  background: #f3f3f3;
  margin: 0;
  padding: 0;
  font-family: Roboto, sans-serif;
  overflow: hidden;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: 18px 0;
}

input[type="range"]:focus {
  outline: none;
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.color {
  float: left;
  border: .5px solid #eee;
  border-radius: 3px;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  transition: all .15s;
}

.color:hover {
  border: .5px solid #aaa;
  transform: scale(1.3);
}

.boxMouseOver {
  transition: all .2s;
}

.boxMouseOver:hover {
  border: 3px solid #08d;
  border-radius: 3px;
  transform: scale(1.05);
}

.leftBox {
  z-index: 2;
  background: #fff;
  width: 200px;
  height: 100vh;
  padding: 13px;
  font-size: .9em;
  display: none;
  position: absolute;
  top: 0;
  box-shadow: 2px 0 3px #ccc;
}

#panel {
  transition-duration: .3s;
  transition-delay: .25s;
  transition-timing-function: ease-in;
  display: block;
  left: -200px;
}

#areaValue {
  color: #333;
  padding: 5px;
  font-size: 1.5em;
  position: absolute;
  top: 0;
  left: 210px;
}

header {
  font-size: 11px;
  display: none;
}

@media print {
  .leftBox {
    display: none;
  }

  header {
    display: block;
  }

  #boxScale, #boxbind, #boxScale, #moveBox, #zoomBox, #boxinfo {
    display: none;
  }

  #lin, #boxgrid {
    width: 27cm;
    height: 19cm;
  }

  #areaValue {
    top: 10px;
    left: 0;
  }

  *, :before, :after, :first-letter, p:first-line, div:first-line, blockquote:first-line, li:first-line {
    color: #000 !important;
    box-shadow: none !important;
    text-shadow: none !important;
    background: none !important;
  }
}

.modalBox {
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.modalBox:after {
  content: "";
  opacity: .5;
  z-index: -1;
  background-image: linear-gradient(#fff, #777);
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.modalBoxContent {
  background: #fff;
  border-radius: 5px;
  width: 300px;
  margin: auto;
  padding: 20px;
  font-size: 1.5em;
  animation-name: animatetop;
  animation-duration: .3s;
  position: relative;
  box-shadow: 0 4px 8px #0003, 0 6px 20px #00000030;
}

@keyframes animatetop {
  from {
    opacity: 0;
    top: -300px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

@keyframes animatedown {
  from {
    opacity: 1;
    top: 0;
  }

  to {
    opacity: 0;
    top: -300px;
  }
}

.area {
  color: "#34e0be";
  fontsize: "12.5px";
  fontweight: "normal";
  display: block;
}

.funkyradio div {
  clear: both;
  overflow: hidden;
}

.funkyradio label {
  border: 1px solid #d1d3d4;
  border-radius: 3px;
  width: 100%;
  font-weight: normal;
}

.funkyradio input[type="radio"]:empty, .funkyradio input[type="checkbox"]:empty {
  display: none;
}

.funkyradio input[type="radio"]:empty ~ label, .funkyradio input[type="checkbox"]:empty ~ label {
  text-indent: 3.25em;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  line-height: 2.5em;
  position: relative;
}

.funkyradio input[type="radio"]:empty ~ label:before, .funkyradio input[type="checkbox"]:empty ~ label:before {
  content: "";
  background: #d1d3d4;
  border-radius: 3px 0 0 3px;
  width: 2.5em;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label, .funkyradio input[type="checkbox"]:hover:not(:checked) ~ label {
  color: #888;
}

.funkyradio input[type="radio"]:hover:not(:checked) ~ label:before, .funkyradio input[type="checkbox"]:hover:not(:checked) ~ label:before {
  content: "✔";
  text-indent: .9em;
  color: #c2c2c2;
}

.funkyradio input[type="radio"]:checked ~ label, .funkyradio input[type="checkbox"]:checked ~ label {
  color: #777;
}

.funkyradio input[type="radio"]:checked ~ label:before, .funkyradio input[type="checkbox"]:checked ~ label:before {
  content: "✔";
  text-indent: .9em;
  color: #333;
  background-color: #ccc;
}

.funkyradio input[type="radio"]:focus ~ label:before, .funkyradio input[type="checkbox"]:focus ~ label:before {
  box-shadow: 0 0 0 3px #999;
}

.funkyradio-default input[type="radio"]:checked ~ label:before, .funkyradio-default input[type="checkbox"]:checked ~ label:before {
  color: #333;
  background-color: #ccc;
}

.funkyradio-primary input[type="radio"]:checked ~ label:before, .funkyradio-primary input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #337ab7;
}

.funkyradio-success input[type="radio"]:checked ~ label:before, .funkyradio-success input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #5cb85c;
}

.funkyradio-danger input[type="radio"]:checked ~ label:before, .funkyradio-danger input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #d9534f;
}

.funkyradio-warning input[type="radio"]:checked ~ label:before, .funkyradio-warning input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #f0ad4e;
}

.funkyradio-info input[type="radio"]:checked ~ label:before, .funkyradio-info input[type="checkbox"]:checked ~ label:before {
  color: #fff;
  background-color: #5bc0de;
}

.roomColor {
  float: left;
  border-radius: 5px 5px 18px;
  width: 36px;
  height: 36px;
  margin: 3px;
  transition: all .1s ease-in-out;
}

.roomColor:hover {
  cursor: pointer;
  border: 1px solid #333;
  transform: scale(1.1);
}

.btn {
  margin-bottom: 6px;
  transition: all .3s;
}

.fully {
  width: 100%;
}

.halfy {
  width: 47%;
}

.btn:hover {
  background: #08d;
}

.ovv {
  transition: all .1s;
}

.circle_css {
  fill: #5cba79;
  fill-opacity: .9;
  transition: all .4s;
}

.circle_css_2 {
  stroke: #5cba79;
  stroke-width: 0;
  fill: #5cba79;
  fill-opacity: .9;
  animation: .6s linear infinite myAnim;
}

@keyframes myAnim {
  0% {
    stroke-width: 0;
  }

  50% {
    stroke-width: 8px;
  }

  100% {
    stroke-width: 0;
  }
}

@keyframes myAnim2 {
  0% {
    stroke-width: 4px;
  }

  50% {
    stroke-width: 7px;
  }

  100% {
    stroke-width: 4px;
  }
}

.circle_css_3 {
  stroke: #5cba79;
  stroke-width: 5px;
  fill: #d7f00b;
  fill-opacity: 1;
  transition: all .4s;
}

.circleGum {
  fill-opacity: .2;
  fill: #e1f410;
  stroke-width: 4px;
  stroke: #5cba79;
  transition: all .5s;
  animation: .6s linear infinite myAnim2;
}

.line_css {
  stroke: #0000;
  stroke-width: 15px;
  transition: all .2s;
}

.trash_css {
  stroke: #0000;
  stroke-width: 5px;
  transition: all .2s;
}

.trash_css:hover {
  stroke: red;
  stroke-width: 5px;
  stroke-opacity: .5;
  cursor: pointer;
}

.tool_color {
  float: left;
  width: 30px;
  height: 30px;
  margin: 2.2px;
}

#ex1Slider .slider-selection {
  background: #bec7cd;
}
/*# sourceMappingURL=index.6d8bd6ec.css.map */
